import React, { useEffect, useState } from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import NavGrid from "../components/navGrid"
import { Device } from "../components/theme"
import { handleVerificationRedirectURLMessage, handleVerificationRedirectURLSuccessStatus} from "../utils/redirectURLHandler"

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(8,1fr);
    grid-gap: 2rem;

    @media ${Device.mobile} {
      grid-template-columns: 1fr;
    }
`

const Content = styled.div`
    display: grid;
    grid-column-start: 3;
    grid-column-end: 7;
    line-height: 1.5;

    @media ${Device.mobile} {
      margin: 1rem;
      grid-column: 1;
    }
`


export default function Verification() {

  const windowLocationHref = typeof window !== `undefined` ? window.location.href : ""

  const [isVerificationSuccessfull] = useState(handleVerificationRedirectURLSuccessStatus(windowLocationHref))
  const [verificationMessage] = useState(handleVerificationRedirectURLMessage(windowLocationHref))

  useEffect(() => {

    if(isVerificationSuccessfull !== undefined && verificationMessage !== undefined){
          if(isVerificationSuccessfull === 'true'){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
              window.location.replace("zettl.app://verifikation");
             }
          }
    } else {
      window.location.replace("https://zettl.app");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
        <Layout>
            <NavGrid />
            <Grid>
                <Content>
                    <h1>{isVerificationSuccessfull === 'true' ? "Verifizierung erfolgreich!" : "Fehler bei Verifizierung!"}</h1>
                    <p>{verificationMessage}</p>
                </Content>
            </Grid>
        </Layout>
  )
}
