export const handleVerificationRedirectURLMessage = (url) => {
    const verificationMessage = decodeURI(getUrlParam('message', undefined, url))

    switch (verificationMessage) {

        case 'Your email was verified. You can continue using the application.':
            return 'Ihre E-mail Adresse wurde erfolgreich verifiziert. Sie können die Zettl-App nun verwenden.'
        case 'This URL can be used only once':
            return 'Der Verifizierungslink wurde bereits benutzt.'

        case 'Access expired.':
            return 'Der Verifizeriungslink ist abgelaufen'

        case 'User account does not exist or verification code is invalid.':
            return 'Das Benutzerkonto existiert nicht oder der Verifizierungscode ist ungültig.'

        case 'This account is already verified.':
            return 'Das Benutzerkonto wurde bereits verifiziert'
        default:
            return undefined;
    }

}

export const handleVerificationRedirectURLSuccessStatus = (url) => {

    return decodeURI(getUrlParam('success', undefined, url))

}

function getUrlVars(url) {
    let vars = {};
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
}

function getUrlParam(parameter, defaultvalue, url) {
    let urlparameter = defaultvalue;
    if (url.indexOf(parameter) > -1) {
        urlparameter = getUrlVars(url)[parameter];
    }
    return urlparameter;
}
